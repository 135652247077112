@import "./variables.less";

@body-bg: #FDFAF4;

// text overrides
@font-size-base: 15px;
@font-family-sans-serif: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
@text-color: #6C6F73;
@headings-color: #2D3238;
@line-height-base: 1.4;


// gray override
@gray-light: rgba(0,0,0,.5);


// h3 override
@font-size-h3: 28px;

// nav tranpsarent
@navbar-transparent-brand-hover-bg:      transparent;
@navbar-transparent-toggle-hover-bg:     transparent;
@navbar-transparent-toggle-border-color: transparent;


// nav bordered
@nav-bordered-color:              #000;
@nav-bordered-color-active:       @brand-primary;
@nav-bordered-font-weight:        300;
@nav-bordered-font-weight-active: 700;


// brand
// @brand-primary: #2595FF;
@brand-primary: #d7a180;


// misc typography
@headings-font-weight: 500;
@btn-font-weight:      400;
@letter-spacing-base:  normal;


// lead
@lead-letter-spacing: @letter-spacing-base;
@lead-font-size:      floor((@font-size-base * 1.5));


// blocks
@block-title-font-size-sm: floor((@font-size-base * 2.8));
@block-title-font-size:    floor((@font-size-base * 2.5));
@block-lead-font-size-sm:  floor((@font-size-base * 1.4));
@block-vertical-padding:   100px;

// buttons
@border-radius-base:        0px;
@border-radius-large:       0px;
@border-radius-small:       3px;

.b-a-0 { border: none !important; }
.b-x-0 { border-left: none !important; border-right: none !important; }
.b-y-0 { border-top: none !important; border-bottom: none !important; }
.b-t-0 { border-top: none !important; }
.b-b-0 { border-bottom: none !important; }
.b-l-0 { border-left: none !important; }
.b-r-0 { border-right: none !important; }

// placeholder overwrite
.placeholder(@color: @input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: @color;
    font-style: italic;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    font-size: 15px;
  }
  &:-ms-input-placeholder { color: @color; font-style: italic; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: @color; font-style: italic; } // Safari and Chrome
}

// extend
.p-a-tiny { padding:        (@spacer-y * 0.25) !important; }
.p-t-tiny { padding-top:    (@spacer-y * 0.25) !important; }
.p-r-tiny { padding-right:  (@spacer-y * 0.25) !important; }
.p-b-tiny { padding-bottom: (@spacer-y * 0.25) !important; }
.p-l-tiny { padding-left:   (@spacer-y * 0.25) !important; }
.p-x-tiny { padding-right:  (@spacer-x * 0.25) !important; padding-left:   (@spacer-x * 0.5) !important; }
.p-y-tiny { padding-top:    (@spacer-y * 0.25) !important; padding-bottom: (@spacer-y * 0.5) !important; }

.m-a-tiny { margin:        (@spacer-y * 0.25) !important; }
.m-t-tiny { margin-top:    (@spacer-y * 0.25) !important; }
.m-r-tiny { margin-right:  (@spacer-y * 0.25) !important; }
.m-b-tiny { margin-bottom: (@spacer-y * 0.25) !important; }
.m-l-tiny { margin-left:   (@spacer-y * 0.25) !important; }
.m-x-tiny { margin-right:  (@spacer-x * 0.25) !important; margin-left:   (@spacer-x * 0.5) !important; }
.m-y-tiny { margin-top:    (@spacer-y * 0.25) !important; margin-bottom: (@spacer-y * 0.5) !important; }

.p-a-xs { padding:        (@spacer-y * 0.5) !important; }
.p-t-xs { padding-top:    (@spacer-y * 0.5) !important; }
.p-r-xs { padding-right:  (@spacer-y * 0.5) !important; }
.p-b-xs { padding-bottom: (@spacer-y * 0.5) !important; }
.p-l-xs { padding-left:   (@spacer-y * 0.5) !important; }
.p-x-xs { padding-right:  (@spacer-x * 0.5) !important; padding-left:   (@spacer-x * 0.5) !important; }
.p-y-xs { padding-top:    (@spacer-y * 0.5) !important; padding-bottom: (@spacer-y * 0.5) !important; }

.m-a-xs { margin:        (@spacer-y * 0.5) !important; }
.m-t-xs { margin-top:    (@spacer-y * 0.5) !important; }
.m-r-xs { margin-right:  (@spacer-y * 0.5) !important; }
.m-b-xs { margin-bottom: (@spacer-y * 0.5) !important; }
.m-l-xs { margin-left:   (@spacer-y * 0.5) !important; }
.m-x-xs { margin-right:  (@spacer-x * 0.5) !important; margin-left:   (@spacer-x * 0.5) !important; }
.m-y-xs { margin-top:    (@spacer-y * 0.5) !important; margin-bottom: (@spacer-y * 0.5) !important; }

.p-a-xl { padding:        (@spacer-y * 5) !important; }
.p-t-xl { padding-top:    (@spacer-y * 5) !important; }
.p-r-xl { padding-right:  (@spacer-y * 5) !important; }
.p-b-xl { padding-bottom: (@spacer-y * 5) !important; }
.p-l-xl { padding-left:   (@spacer-y * 5) !important; }
.p-x-xl { padding-right:  (@spacer-x * 5) !important; padding-left:   (@spacer-x * 5) !important; }
.p-y-xl { padding-top:    (@spacer-y * 5) !important; padding-bottom: (@spacer-y * 5) !important; }

.m-a-xl { margin:        (@spacer-y * 5) !important; }
.m-t-xl { margin-top:    (@spacer-y * 5) !important; }
.m-r-xl { margin-right:  (@spacer-y * 5) !important; }
.m-b-xl { margin-bottom: (@spacer-y * 5) !important; }
.m-l-xl { margin-left:   (@spacer-y * 5) !important; }
.m-x-xl { margin-right:  (@spacer-x * 5) !important; margin-left:   (@spacer-x * 5) !important; }
.m-y-xl { margin-top:    (@spacer-y * 5) !important; margin-bottom: (@spacer-y * 5) !important; }

.m-nt-tiny { margin-top:    (@spacer-y * 0.125 * -1) !important; }
.m-nr-tiny { margin-right:  (@spacer-y * 0.125 * -1) !important; }
.m-nb-tiny { margin-bottom: (@spacer-y * 0.125 * -1) !important; }
.m-nl-tiny { margin-left:   (@spacer-y * 0.125 * -1) !important; }

.m-nt-xs { margin-top:    (@spacer-y * 0.25 * -1) !important; }
.m-nr-xs { margin-right:  (@spacer-y * 0.25 * -1) !important; }
.m-nb-xs { margin-bottom: (@spacer-y * 0.25 * -1) !important; }
.m-nl-xs { margin-left:   (@spacer-y * 0.25 * -1) !important; }

.m-nt { margin-top:    (@spacer-y * 0.5 * -1) !important; }
.m-nr { margin-right:  (@spacer-y * 0.5 * -1) !important; }
.m-nb { margin-bottom: (@spacer-y * 0.5 * -1) !important; }
.m-nl { margin-left:   (@spacer-y * 0.5 * -1) !important; }

.m-nt-md { margin-top:    (@spacer-y * 1 * -1) !important; }
.m-nr-md { margin-right:  (@spacer-y * 1 * -1) !important; }
.m-nb-md { margin-bottom: (@spacer-y * 1 * -1) !important; }
.m-nl-md { margin-left:   (@spacer-y * 1 * -1) !important; }

.m-nt-lg { margin-top:    (@spacer-y * 1.5 * -1) !important; }
.m-nr-lg { margin-right:  (@spacer-y * 1.5 * -1) !important; }
.m-nb-lg { margin-bottom: (@spacer-y * 1.5 * -1) !important; }
.m-nl-lg { margin-left:   (@spacer-y * 1.5 * -1) !important; }



// half columns
.col-xs-push-half {
  left: percentage((1 / @grid-columns) / 2);
}
.col-xs-pull-half {
  right: percentage((1 / @grid-columns) / 2);
}

.col-sm-push-half {
  @media (min-width: @screen-sm-min) {
    left: percentage((1 / @grid-columns) / 2);
  }
}
.col-sm-pull-half {
  @media (min-width: @screen-sm-min) {
    right: percentage((1 / @grid-columns) / 2);
  }
}

.col-md-push-half {
  @media (min-width: @screen-md-min) {
    left: percentage((1 / @grid-columns) / 2);
  }
}
.col-md-pull-half {
  @media (min-width: @screen-md-min) {
    right: percentage((1 / @grid-columns) / 2);
  }
}

.col-lg-push-half {
  @media (min-width: @screen-lg-min) {
    left: percentage((1 / @grid-columns) / 2);
  }
}
.col-lg-pull-half {
  @media (min-width: @screen-lg-min) {
    right: percentage((1 / @grid-columns) / 2);
  }
}

@import "./components.less";
